/* ========================================================== */
/* Youtube embed
/* ========================================================== */
.au-youtube-container {
	position: relative;
	overflow: hidden;
	width: 100%;
	margin-bottom: 1.3636364rem;
}

/* ========================================================== */
/* Youtube embed: Video element
/* ========================================================== */
.au-youtube-video {
	position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  background-color: #000;
}

.au-youtube-video__poster {
	position: absolute;
	top: 0; bottom: 0;
	left: 0; right: 0;
	width: 100%; height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;
}

.au-youtube-video__embed {

	iframe {
  	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100%;
  }
}

.au-youtube-video__play {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	position: relative;
	z-index: 2;
	width: 45px;
	height: 45px;
	border-radius: 50%;
	background-color: #000;
	background-color: rgba(0, 0, 0, 0.6);
	cursor: pointer;
	vertical-align: middle;
	backface-visibility: hidden;
	-webkit-touch-callout: none;
	user-select: none;
	transition: background-color 0.3s ease-in-out;

	@media (min-width: 640px) {
		width: 55px;
		height: 55px;
	}

	svg {
		display: inline-block;
		max-width: 14px;
		margin-left: 3px;
		transition: opacity 0.3s ease-in-out;

		@media (min-width: 640px) {
			max-width: 17px;
		}
	}

	&:hover {

		svg {
			opacity: 0.7;
		}
	}
}

// When video is playing
.au-youtube-video.au-youtube-video--playing {

  .au-youtube-video__play {
    animation: fadeOutDownSmall 0.6s 0.1s both;
  }

  .au-youtube-video__poster {
    opacity: 0;
    transition: opacity 1s 0.6s ease;
  }

  .au-youtube-video__embed {
    opacity: 1;
    visibility: visible;
    z-index: 3;
    transition: z-index 0s 1.5s, visibility 0s 1.5s, opacity 0s 1.5s;
  }
}

/* ========================================================== */
/* Youtube embed: Info element
/* ========================================================== */
.au-youtube-info {
	position: relative;
	padding: 15px 20px 20px;

	@media (min-width: 640px) {
		padding: 15px 30px 25px;
	}
}

/*
/* Youtube embed info: Toolbar
/* -------------------------------------------------- */
.au-youtube-info__toolbar {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	color: rgba(255, 255, 255, 0.8);
	line-height: 1.1;

	> div {
		display: flex;
	}

	span {
		font-size: 12px;
		font-weight: normal;
		font-family: 'AUPassataRegular', sans-serif;
		color: inherit;
	}

	svg {
		max-height: 13px;
		max-width: 13px;
		margin-top: -2px;
	}
}

.au-youtube-info__views {
	flex-wrap: wrap;

	> span {
		margin-right: 5px;
	}
}

.au-youtube-info__data {
	display: flex;
	flex-direction: row;
	align-items: center;

	> div {
    display: flex;
    align-items: center;
		margin-left: 15px;
	}

	> div span {
		margin-left: 6px;
	}
}

/*
/* Youtube embed info: Content
/* -------------------------------------------------- */
.au-youtube-info__content {
	margin-top: 20px;
}

.au-youtube-info__heading {
	display: block;
	margin: 0 0 10px;
	font-family: 'AUPassataRegular';
	font-weight: bold;
	font-size: 17px;
	line-height: 1.4;
	color: #fff;
}

.au-youtube-info__text {
	display: block;
	font-family: 'Georgia';
	font-size: 14px;
	line-height: 1.6;
	color: rgba(255, 255, 255, 0.8);
}

/*
/* Youtube embed info: If light bg color
/* -------------------------------------------------- */
.au-youtube-info.au-youtube-info--light-bg {

	.au-youtube-info__heading,
	.au-youtube-info__toolbar,
	.au-youtube-info__text {
		color: #233167;
	}

	svg path {
		fill: #233167;
	}
}