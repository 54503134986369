// ==========================================================
// Animations
// ==========================================================

.animated {
  animation-duration: 0.6s;
  animation-fill-mode: both;
}

/*
/* Move in up
/* -------------------------------------------------- */
@keyframes moveInUp {
  from, to {
    animation-timing-function: ease-in-out;
  }

  from {
    opacity: 0;
    transform: translate3d(0, 100px, 0);
  }

  30% {
    opacity: 1;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

/*
/* Move in down
/* -------------------------------------------------- */
@keyframes moveInDown {
  from, to {
    animation-timing-function: ease-in-out;
  }

  from {
    opacity: 0;
    transform: translate3d(0, -100px, 0);
  }

  30% {
    opacity: 1;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

/*
/* Fade in
/* -------------------------------------------------- */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

/*
/* Fade in from zero opacity
/* -------------------------------------------------- */
@keyframes fadeInZero {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeInZero {
  animation-name: fadeInZero;
}

/*
/* Fade out
/* -------------------------------------------------- */
@keyframes fadeOut {

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

/*
/* Fade in up
/* -------------------------------------------------- */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
  // animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

/*
/* Fade in up small
/* -------------------------------------------------- */
@keyframes fadeInUpSmall {
  0% {
    opacity: 0;
    transform: translate3d(0, 5rem, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.fadeInUpSmall {
  animation-name: fadeInUpSmall;
}

/*
/* Fade in down
/* -------------------------------------------------- */
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.fadeInDown {
  animation-name: fadeInDown;
 }

 /*
/* Fade in down small
/* -------------------------------------------------- */
@keyframes fadeInDownSmall {
  0% {
    opacity: 0;
    transform: translate3d(0, -4rem, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.fadeInDownSmall {
  animation-name: fadeInDownSmall;
 }

/*
/* Fade out down
/* -------------------------------------------------- */
@keyframes fadeOutDown {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  animation-name: fadeOutDown;
}

/*
/* Fade out down small
/* -------------------------------------------------- */
@keyframes fadeOutDownSmall {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate3d(0, 4rem, 0);
  }
}

.fadeOutDown {
  animation-name: fadeOutDown;
}

/*
/* Fade out up
/* -------------------------------------------------- */
@keyframes fadeOutUp {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  animation-name: fadeOutUp;
}

/*
/* Fade out up small
/* -------------------------------------------------- */
@keyframes fadeOutUpSmall {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -4rem, 0);
  }
}

.fadeOutUpSmall {
  animation-name: fadeOutUpSmall;
}

/*
/* Fade in left
/* -------------------------------------------------- */
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

/*
/* Fade in left small
/* -------------------------------------------------- */
@keyframes fadeInLeftSmall {
  from {
    opacity: 0;
    transform: translate3d(-4rem, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInLeftSmall {
  animation-name: fadeInLeftSmall;
}

/*
/* Fade in right
/* -------------------------------------------------- */
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

/*
/* Fade in right small
/* -------------------------------------------------- */
@keyframes fadeInRightSmall {
  from {
    opacity: 0;
    transform: translate3d(4rem, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRightSmall {
  animation-name: fadeInRightSmall;
}

/*
/* Fade out left
/* -------------------------------------------------- */
@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  animation-name: fadeOutLeft;
}

/*
/* Fade out left small
/* -------------------------------------------------- */
@keyframes fadeOutLeftSmall {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-4rem, 0, 0);
  }
}

.fadeOutLeftSmall {
  animation-name: fadeOutLeftSmall;
}

/*
/* Fade and scale in
/* -------------------------------------------------- */
@keyframes fadeScaleIn {
  from, to {
    animation-timing-function: ease-in-out;
  }

  from {
    opacity: 0;
    transform: scale(0);
  }

  40% {
    opacity: 1;
  }

  to {
    transform: scale(1);
  }
}

.fadeScaleIn {
  animation-name: fadeScaleIn;
}

/*
/* Pulse up / down animation
/* -------------------------------------------------- */
@keyframes pulseUpDown {
  0%, 25%, 75%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
}