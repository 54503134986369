/* ========================================================== */
/* Image carousel
/* ========================================================== */
.au-carousel-container {
	position: relative;
	overflow: hidden;
	width: 100%;
	margin-bottom: 1.3636364rem;

	// Hide carousel until initalized
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
}

.au-carousel {
	position: relative;
	overflow: hidden;
	width: 100%;
}

.au-carousel-item {
	width: 100%;
	margin: 0;
}

/*
/* Image carousel: Image
/* -------------------------------------------------- */
.au-carousel-image {

	img {
		display: block;
		width: 100%;
		height: auto;
	}
}

/*
/* Image carousel: Counter
/* -------------------------------------------------- */
.au-carousel-counter {
	position: absolute;
	top: 20px;
	right: 20px;
	z-index: 3;
	color: #fff;
	font-size: 14px;
	font-family: 'AUPassataRegular', sans-serif;
	line-height: 1;

	span {
		display: inline;
	}
}

/*
/* Image carousel: Arrows
/* -------------------------------------------------- */
.au-carousel {

	.flickity-prev-next-button {
	  position: absolute;
	  bottom: 0;
	  width: 38px;
	  height: 38px;
	  padding: 0;
	  border: none;
	  background: #fff;
	  border-radius: 0;
	  cursor: pointer;
	  appearance: none;
		-webkit-touch-callout: none;
    transition: background 0.2s ease-in-out;

    display: flex;
    align-items: center;
    justify-content: center;
	}

	.flickity-prev-next-button:not(:disabled):hover {
		background: white;
	}

	.flickity-prev-next-button:focus {
	  outline: none;
	}

	.flickity-prev-next-button:active {
	  opacity: 0.6;
	}

	.flickity-prev-next-button.previous { right: 38px; }
	.flickity-prev-next-button.next { right: 0; }

	.flickity-prev-next-button:disabled {
	  opacity: 0.5;
	  cursor: auto;
	}

	.flickity-prev-next-button svg {
	  display: flex;
	  height: 12px;
    width: 100%;
	}

	.flickity-prev-next-button.previous svg {
	  margin-left: 2px;
	}
	.flickity-prev-next-button.next svg {
	  margin-left: -2px;
	}

	.flickity-prev-next-button .arrow {
	  fill: #333;
	}

	/*
	/* Media queries
	/* -------------------------------------------------- */
	@media (min-width: 640px) {

		.flickity-prev-next-button {
		  top: 50%;
		  bottom: auto;
		  width: 42px;
		  height: 42px;
		  border-radius: 50%;
		  background: hsla(0, 0%, 100%, 0.75);
	    transform: translateY(-50%);
		}

		.flickity-prev-next-button.previous { left: 16px; }
		.flickity-prev-next-button.next { right: 16px; }
	}
}

/* ========================================================== */
/* Image carousel captions
/* ========================================================== */
.au-carousel-caption-container {
	display: flex;
	margin-top: -2px;

	@media (min-width: 640px) {
    position: absolute;
		bottom: 16px;
		left: 16px; right: 16px;
	}
}

.au-carousel-caption {
	position: relative;
	display: none;
	overflow: hidden;
	width: 100%;

	@media (min-width: 640px) {
    height: auto !important;
	}
}

.au-carousel-caption.is-selected {
	display: flex;

	.au-carousel-caption__text {
		animation-name: fadeIn;
	}
}

.au-carousel-caption__text {
	position: relative;
	width: 100%;
	z-index: 2;
	padding: 16px 20px;
	color: #fff;
	font-family: 'Georgia';
	font-size: 14px;
	line-height: 1.6;

	animation-name: fadeOut;
  animation-delay: 0s;
  animation-duration: 1.2s;
  animation-fill-mode: both;
  animation-timing-function: ease;
}

.au-carousel-caption__text--dark {
	color: #333;
}

.au-carousel-caption__bg {
	position: absolute;
	top: 0; bottom: 0;
	left: 0; right: 0;
	z-index: 1;

	@media (min-width: 640px) {
    opacity: 0.95;
	}
}